import React, { FC } from 'react';
import IconProps from './IconProps';

const Home2Icon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';
  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.59984 16.9999L6.39934 14.1928C6.29154 12.6836 7.4868 11.3999 8.99985 11.3999C10.5129 11.3999 11.7082 12.6836 11.6003 14.1928L11.3998 16.9999"
        stroke="#5A5766"
        strokeWidth="1.2"
      />
      <path
        d="M1.28111 9.97081C0.998696 8.13297 0.857488 7.21411 1.20494 6.39951C1.55238 5.5849 2.32323 5.02755 3.86492 3.91285L5.0168 3.08C6.93464 1.69334 7.89355 1 9.00003 1C10.1064 1 11.0654 1.69334 12.9832 3.08L14.1351 3.91285C15.6768 5.02755 16.4476 5.5849 16.7951 6.39951C17.1425 7.21411 17.0013 8.13297 16.7189 9.97081L16.4781 11.5379C16.0777 14.1431 15.8776 15.4458 14.9432 16.2229C14.0089 17 12.6429 17 9.91107 17H8.08899C5.35708 17 3.99113 17 3.0568 16.2229C2.12247 15.4458 1.9223 14.1431 1.52194 11.5379L1.28111 9.97081Z"
        stroke="#5A5766"
        strokeWidth="1.2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Home2Icon;
